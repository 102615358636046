

























































































.graph-sidebar
  width 100%
