// === VARIABLES ===

// COLORS
$primary         = #F7BBBF
$secondary       = #373737
// $tertiary       =
$black           = #000000
$dark            = #464749
$grey            = #B4B2A9
$silver          = #DDD9D3
$light           = #F3F1EF
$dash-light      = #F4F4F4
$dark-pink       = #f3afb3
$infos           = #8a8686
$neutral         = #E0E1E2
$positive        = #21BA45
$positive-darker = #198f35
$negative        = #E91E63
$negative-darker = #c1134e
$info            = #31CCEC
$warning         = #F2C037
$warning-on-dark = #FF6347

// BORDERS
$border-light           = 1px
$border-medium          = 2px
$border-thick           = 4px
$border-radius-smooth   = 4px
$border-radius          = 10px
$border-radius-rounded  = 20px
$border-radius-circle   = 50%

// SHADOWS
$shadow-elevation-1 = 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)
$shadow-elevation-2 = 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)
$shadow-elevation-3 = 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)
$shadow-elevation-4 = 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)
$shadow-elevation-5 = 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)
$shadow-card = 0 0 1px 0 rgba(9, 30, 66, 0.31), 0 2px 4px -1px rgba(9, 30, 66, 0.25)

// SPACE
$space-tiny   = 2px
$space-little = 4px
$space-1      = 8px
$space-2      = 16px
$space-3      = 32px
$space-4      = 64px
$space-giant  = 128px

// GRID
$grid-border = 1px solid $dash-light

// FONTS
$font-color       = #373737

$fs-big       = 2rem
$fs-h1        = 1.6rem
$fs-h2        = 1.3rem
$fs-h3        = 1.1rem
$fs-body      = 1rem
$fs-little    = 0.8rem

$fw-extra-bold = 900
$fw-bold       = 700
$fw-regular    = 400
$fw-light      = 300

$nfs-big      = 2.6rem
$nfs-h3       = 1.6rem
$nfs-body     = 1.1rem

$system-fonts     = local('Verdana'), local('Helvetica'), local('Arial'), local('Open Sans')

@font-face
  font-family 'Lato'
  font-style normal
  font-weight 300
  src:
    url('/fonts/Lato/Light/Lato-Light.ttf') format('truetype'),
    url('/fonts/Lato/Light/Lato-Light.woff2') format('woff2'),
    url('/fonts/Lato/Light/Lato-Light.woff') format('woff')

@font-face
  font-family 'Lato'
  font-style normal
  font-weight 400
  src:
    url('/fonts/Lato/Regular/Lato-Regular.ttf') format('truetype'),
    url('/fonts/Lato/Regular/Lato-Regular.woff2') format('woff2'),
    url('/fonts/Lato/Regular/Lato-Regular.woff') format('woff')

@font-face
  font-family 'Lato'
  font-style normal
  font-weight 700
  src:
    url('/fonts/Lato/Bold/Lato-Bold.ttf') format('truetype'),
    url('/fonts/Lato/Bold/Lato-Bold.woff2') format('woff2'),
    url('/fonts/Lato/Bold/Lato-Regular.woff') format('woff')

@font-face
  font-family 'Lato'
  font-style normal
  font-weight 900
  src:
    url('/fonts/Lato/Black/Lato-Black.ttf') format('truetype'),
    url('/fonts/Lato/Black/Lato-Black.woff2') format('woff2'),
    url('/fonts/Lato/Black/Lato-Black.woff') format('woff')
