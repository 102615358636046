























@import '~variables'

.graph-card
  margin-bottom $border-thick
  max-width 400px
  .graph-card__header
    margin-bottom $space-2
